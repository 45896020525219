#logo-image {
    width: 200px;
    height: auto;
}

@media (min-size: 900px) {
    #logo-image {
        max-width: 500px;
        height: auto;
    }
}