.GalleryItem {
    transition: all .2s ease-in-out;
    opacity: 0.90;
    flex: 20%;
    max-width: 20%;
    padding: 0 4px;
}

.GalleryItem:hover {
    opacity: 1;
}

.GalleryContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 4px;
}

.GalleryItem img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .GalleryItem {
        flex: 30%;
        max-width: 30%;
        padding: 0 4px;
    }
}